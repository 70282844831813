@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.lineResults {
  background-color: #7366ff;

}
.IconPatient{
 color: #0079C7;
}


.llenoResult {
  background-color: rgba(0, 121, 199, 0.1)

}

.divHeader{
  /* background: linear-gradient(270deg, #0079C7 0%, #FFFFFF 85.94%); */
  background: linear-gradient(270deg, #0079C7 0%, #FFFFFF 85.94%);


}


.cardPat{
  background-color: rgba(0, 121, 199, 0.15);
}

/* 01012208260004 */


.LabelLogin {
  color: #848484;
  font-size: 32px;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  text-align: center;
}

.LabelLogin2 {
  color: #0079C7;
  font-size: 32px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400 ;
  /* font-weight: lighter */
}

.TitleCaptureResults {
  color: #ffffff;

  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  text-align: center;
}

.hrC {
  color: #46AFE5 !important;
}

.labelCard{
  color: #4D4D4D;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
}

.scrollAnalyte {
  width: 1380px;
  height: 420px;
  overflow: auto;
}


.bgResults {
  /* background-image: url('../images/login/loginTm2.svg'); */
  /* background-image: url('../../assets/images/loginHelenLabs/ResultsFrom.svg'); */
  background-image: url('../../assets/images/loginHelenLabs/ResultsNIm/LoginHelenResult.jpg');
  width: 100%;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  z-index: -50;
}

.img-login {
  /* background-image: url('../images/login/loginTm2.svg'); */
  /* background-image: url('../../assets/images/loginHelenLabs/ResultsFrom.svg'); */
  background-image: url('../../assets/images/toma/login.jpg');
  width: 100%;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  z-index: -50;
}
/* .bg-toma
{
  background-image: url('../images/login/loginTm.svg');
  width: 100%;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  z-index: -50;
} */






  .prueba{
    background: #a70d0d;
  }

  
.labelMenu {
  color: #000000;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  /* font-weight: bold; */
  text-align: center;
}

.hijo {
  position: absolute;
  top: 25%;
  left: 0%;
  margin: -25px 0 0 -25px;
}

.col1Image {
  top: 25%;
  left: 0%;
}

.conA{
  
  max-width: 575.99px !important;

}

.logoH{
  /* max-width: 575.99px !important; */
  max-width: 1360px !important;

}


.font-method1{
  color: #848484;
}

.fontSizeA{
  font-size: 10px;
}

.lineColor{
  color: #0079C7;
}




@media screen and (max-width: 1460px) {
  .TitleCaptureResults{
    font-size: 24px !important;
  }
  .labelDate{
    font-size: 13px !important;
  }
  .labelExam{
    font-size: 14px !important;
  }
  .labelmethod{
    font-size: 12px !important;
  }
  .labelMethod{
    color: #848484 !important;
    font-size: 10px !important;
  }
  .labelExams{
    color: #000000 !important;
    font-size: 12px !important;
  }
  .labelTxtAlert{
    color: #000000 !important;
    font-size: 10px !important;
  }
  .fontsizeTable{
    width: 50%;
  }
  .fontsizeChart{
    width: 480;

  }
  .fontsizeMovilChart {
    width: 380px !important;
  }

  .fontProfilePatien {
    width: 60%;
  }


}

@media screen and (max-width: 1199.5px) {
  .conA{
    width: 40% !important;
  }
  .logoH{
    width: 20% !important;
  }
  .logoD{
    width: 20% !important;
  }
  .TitleCaptureResults{
    font-size: 24px !important;
  }
  .fontsizeMovilChart {
    width: 380px !important;
  }

  .fontProfilePatien {
    width: 60%;
  }
}

@media screen and (max-width: 1130px){
  .conA{
    width: 110% !important;
  }
  .logoH{
    width: 20% !important;
  }
  .logoD{
    width: 40% !important;
  }
  .TitleCaptureResults{
    font-size: 24px !important;
  }
  .fontsizeMovilChart {
    width: 380px !important;
  }
  .fontProfilePatien {
    width: 60%;
  }
  
}


@media screen and (max-width: 575.99px){
  .conA{
    width: 100% !important;
  }
  .logoH{
    width: 100% !important;
  }
  .logoD{
    width: 98% !important;
  }
  .TitleCaptureResults{
    font-size: 16px !important;
  }
  .LableInfo {
    font-size: 10px;
  }
  .imgHlabs{
    width: 80% !important;
  }
  .labelDate{
    font-size: 10px !important;
  }
  .labelExam{
    font-size: 12px !important;
  }
  .labelmethod{
    font-size: 10px !important;
  }
  .labelMethod{
    color: #848484 !important;
    font-size: 10px !important;
  }
  .labelExams{
    color: #000000 !important;
    font-size: 12px !important;
  }
  .labelTxtAlert{
    color: #000000 !important;
    font-size: 7px !important;
  }

  .fontsizeMovilChart {
    width: 380px !important;
  }

  .fontProfilePatien {
    width: 100%;
  }


}

.iconColor{
  color: #0079C7;
}
