.bgCard {
    background-color: #abe0fb;
}

.labelCardD {
    color: #fff;
}

.imgU {
    width: 95% !important;
}

.refusedGlobal{
    background: linear-gradient(270deg, hsla(354, 70%, 54%, 1) 0%, hsla(0, 0%, 97%, 1) 100%);
    padding: 13px 20px 13px 65px;
    border-color: #dc3545;
    /* color: black !important; */
}

.table-wrapper {
    width: 100%;
    height: 400px; /* Altura de ejemplo */
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: #00294094 rgba(0, 0, 0, 0);
  }
  
  .table-wrapper table thead {
    position: -webkit-sticky; /* Safari... */
    position: sticky;
    top: 0;
    left: 0;
  }
  .sticky-footer {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    background-color: #033d5b; 
    z-index: 1; 
  }

.deni-react-treeview-container5 {
    font-family: tahoma, arial;
    font-size: 12px;
    padding: 0px;
    overflow-x: auto;
    overflow-y: auto;
    border: solid 1px;
    border-color: transparent !important;
    width: 400px;
    height: 350px;
    background-color: transparent !important;
}

.deni-react-treeview-container.classic .deni-react-treeview-item-container.classic .icon-and-text.selected {
    background: transparent !important;
    background: transparent !important;
    background: linear-gradient(to bottom, #ebf3f9 0%, #ffffff 100%);
}

.deni-react-treeview-item-container .icon-and-text.selected {
    border: 1px solid;
    border-color: transparent !important;
    padding-left: calc(5px - 1px);
    background: transparent !important;
    /* background: #ebf3f9;
    background: -moz-linear-gradient(top, #ebf3f9 0%, #9DC2E1 100%);
    background: -webkit-linear-gradient(top, #ebf3f9 0%, #9DC2E1 100%);
    background: linear-gradient(to bottom, #ebf3f9 0%, #9DC2E1 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=$selection-background-start-color, endColorstr=$end-color, GradientType=0); */
}

.cardDegradado {
    background: linear-gradient(270deg, #0079C7 0%, #FFFFFF 85.94%);
}

.cardDashboard {
    background: linear-gradient(181deg, #46AFE5 0%, #FFFFFF 35.10%);
}

.cardDashboard-micro {
    background: linear-gradient(181deg, #abff6e 0%, #FFFFFF 35.10%);
}

.cardDashboardFiltros {
    background: linear-gradient(181deg, #46AFE5 0%, #FFFFFF 35.10%);
}


.butto-full-modal {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    height: 56px;
    width: 56px;
    background: rgba(0, 0, 0, .4);
    border-radius: 50%;
    border: none;
    overflow: hidden;
    color: transparent;
    white-space: nowrap;
    color: #fff;

}


.userTrazabilidad {
    background-color: rgba(0, 121, 199, 0.15);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    width: 150px;
    height: 150px;
}

.study-flebotomia {
    font-size: 12px;
    padding: 0px;
    overflow-x: auto;
    overflow-y: auto;
    border: solid 1px;
    background-color: #fafafa;
    border-color: #0079C7;
}

.rectangulo-trazabilidad {
    /* width: 300px; */
    height: 2px;
    background-color: #AA99FF;
}

.label-trazabilidad {
    color: #AA99FF;
}

.cuadrado-trazabilidad {
    /* // width: 100px; */
    height: 100px;
    background-color: rgba(0, 121, 199, .1)
}

.label-trazabilidad-l {
    color: #fff;
}


card-body-I {
    padding: 25px !important;
    background-color: transparent !important;
}


.selected {
    background-color: #ffffffd1 !important;
    color: #fff !important ;
  }
  