@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


.box-reloj {
  border: 3px solid #0079C7;
  background: #46AFE5;
  border-radius: 25px;
  height: 65px;
}

.imgMessage {
  width: 10%;
}

.detailPatienModal {
  color: #6c757d;
}


.tap-top-chat {
  text-align: left;
  cursor: pointer;
  display: none;
  position: fixed;
  bottom: 30px;
  z-index: 5;
  color: transparent;
  background: transparent;
  border-color: transparent;
  padding: 7px;
  box-shadow: 0 0 10px transparent;
  border-radius: 100%;
  opacity: 10;
  transition: all 0.3s ease;
}




.rc {
  color: #ffffff;
  font-size: 25px;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
}


.imgCodeBar {
  width: 500px !important;
}

.bg-barra1 {
  width: 100%;
  min-height: 20vh;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  z-index: -50;
}

.rE {
  color: #000000;
  font-size: 20px;
  font-family: 'Montserrat', sans-serif;
  text-align: center;

}

.negritaLabel {

  /* font-weight: bold; */
  font-weight: 500
}

.tamañoinputID {
  height: 50px !important;
  margin-top: 1px !important;
  right: 60px;
  width: 390px;
  border: 2px solid #525051;
  border-radius: 4px;
  border-radius: 20px;
  /* background-color: rgb(255, 255, 255); */
}

.tamañoinputID::placeholder {
  color: #3b3a3b;
  font-size: 1.2em;
  font-family: 'Montserrat', sans-serif;
  padding-left: 20px !important;

  /* text-align: center !important; */
  size: 40px;
}


.modalC {
  color: #ffffff;
  background-color: #FF00AA;
}

.prueba {
  background-color: #FF00AA;

}

.icon-active {
  color: #FF00AA;

}

.modalButton {
  background-color: #46AFE5;
  font-weight: bold;
  color: #ffffff;
  border: 3px solid #46AFE5;
  border-radius: 15px;
  font-size: 17px;
  font-family: 'Montserrat', sans-serif;
  width: 180px;
  height: 40px;
}




.colorModalIDmuestra {
  background-color: #46AFE5;
  height: 55px;
}

.colorModalIDmuestra2 {
  background-color: #FF00AA;
  height: 55px;
}

.colorModalTM {
  background-color: #09D4FC;
  height: 55px;
}

.colorModal-End-muestra {
  background-color: #09D4FC;
  height: 60px;
}

.labelModal {
  color: #ffffff;
  font-size: 24px;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  text-align: center;

}

.labelModal-end {
  color: #ffffff;
  font-size: 28px;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  text-align: center;

}

.iconModal {
  background-color: #ffffff;
  color: #FF00AA;
  border-radius: 8px;
  font-size: 24px;
}

.iconModal-End {
  background-color: #ffffff;
  color: #09D4FC;
  border-radius: 8px;
  font-size: 24px;
}

.inputIDcard {
  border: 2px solid #3f3f3f;
  border-radius: 4px;
}

.selector {
  opacity: 0.5;
  filter: alpha(opacity=50);
}

/* modifique aqui */


.a {
  color: #000000;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  text-align: center;
}

.ni {
  color: #000000;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
}


.redondo {
  width: 30px;
  height: 30px;
  background-color: #09D4FC;
  margin: 5px;
  padding: 10px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  font-size: 11px;
  line-height: 32px;
  text-transform: uppercase;
  float: left;
}

.redondo:hover {
  opacity: 0.50;
  -moz-opacity: .50;
  filter: alpha (opacity=50);
}

.redondo a {
  color: #fff;
  text-decoration: none;
  padding: 5px 5px 5px 0;
}

.ibu {
  color: #ffffff;
}

.modalS {
  color: #ffffff;
  background-color: #09D4FC;
}

.buttonn {
  background-color: #09D4FC;
  border: none;
  color: white;
  padding: 9px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;

}

.button5 {
  border-radius: 50%;
}


.nameExam {
  color: #000000;
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
  text-align: left;
}

.scrollbars {
  height: 350px;
  width: 200px;
  overflow-y: auto;
}

.contenedor {
  height: 300px;
  background-color: #CCC;
  overflow: scroll;
  overflow-y: auto;
}

.table-scroll {
  height: 400px;
  display: block;

}

.tables-scroll {
  height: 450px;
  display: block;

}


.username-Active {
  width: 260px;
  height: 62px;
  background: #46afe5;
  /* background: #3A24A8; */
  border-radius: 25px;
  box-shadow: 0 0 10px #46afe5;
  /* opacity: 0.5; */
  transition: all 0.3s ease;
  /* transition-property: opacity, left;
  transition-duration: 3s, 5s, 2s, 1s; */
}

.r-label-active {
  color: #ffffff;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  text-align: center;
}

.r-label-active-omitir-Encuesta {
  color: #ffffff;
  font-size: 18px;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  text-align: center;
}

.statusIcon {
  color: #FF00AA;
}

.statusIconI {
  color: #ffffff;
}

.statusIconGris {
  color: #767676;
}

.button-menu-interactive {
  background-color: #3A24A8;
  border: none;
  color: white;
  padding: 10px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  border-radius: 20px;

}

.button-interactive-cicle {
  background-color: #3A24A8;
}

.button-menu-icon {
  color: #ffffff;
}

.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  border-radius: 35px;
  font-size: 24px;
  line-height: 1.33;
  background-color: #3A24A8;
  z-index: 1050;
}

.pointer {
  cursor: pointer;
}

.btn-circleModal.btn-xl {
  width: 30px;
  height: 30px;
  padding: 10px 16px;
  border-radius: 35px;
  font-size: 24px;
  line-height: 1.33;
  background-color: #FF00AA;
  z-index: 1050;
}

.icon-modal-indication {
  color: #09D4FC;

}

.pintar-icon {
  background-color: #09D4FC;
  width: 180px;
  height: 54px;
  border-radius: 19px;
}


.box-pagination-button {
  width: 180px;
  height: 60px;
  border: 3px solid #09D4FC;
  background: #ffffff;
  border-radius: 25px;
}

.r-pagination-button {
  color: #000000;
  font-size: 28px;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
}

.r-pagination-button-10 {
  color: #000000;
  font-size: 24px;
  font-family: 'Montserrat', sans-serif;
  /* text-align: center; */
}

.caja2 {
  -webkit-transition: font-size 1s ease;
  transition: font-size 1s ease;
}

.caja2:hover {
  font-size: 38px;
}





.cuadrado-kit-sampling {
  /* width: 100px;  */
  height: 110px;
  /* background: #3A24A8; */
  /* border-radius: 25px; */
  border: 3px solid #3A24A8;

}

.card-cuadrado-kit-sampling {
  background-color: #3A24A8;

}


.label-cuadrado-kit-sampling {
  color: #000000;
  font-size: 18px;
  font-family: 'Montserrat', sans-serif;

}


.pentagono {
  position: relative;
  width: 54px;
  box-sizing: content-box;
  border-width: 50px 18px 0;
  border-style: solid;
  border-color: #09D4FC;
}

.pentagono:before {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  top: -85px;
  left: -18px;
  border-width: 0 45px 35px;
  border-style: solid;
  border-color: transparent transparent #09D4FC;
}

.label-modal-name {
  color: #000000;
  font-size: 18px;
  font-family: 'Montserrat', sans-serif;

}

.confirm-Button-modal-name {
  background-color: #09D4FC;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  border-radius: 15px;
  font-weight: bold;
  width: 150px;
  height: 40px;

}


.box-pagination-button-encuesta {
  width: 160px;
  height: 50px;
  border: 3px solid #09D4FC;
  background: #ffffff;
  border-radius: 20px;
}

.pintar-icon-encuesta {
  background-color: #09D4FC;
  width: 170px;
  height: 45px;
  /* border-radius: 17px; */
  /* border-radius: 11px; */
  border-top-left-radius: 13px;
  border-bottom-left-radius: 12px;
}




.confirm-Button-2-encuesta-lleno {
  background-color: #09D4FC;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 19px;
  font-family: 'Montserrat', sans-serif;
  border-radius: 16px;
  font-weight: bold;
  width: 220px;
  height: 50px;
}

.confirm-Button-2-encuesta-vacio {
  /* background-color: #09D4FC;
  border: none;
  color: white; */
  background-color: #ffffff;
  border-color: #09D4FC;
  border-width: 3px;
  border-style: solid;
  color: #09D4FC;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 19px;
  font-family: 'Montserrat', sans-serif;
  border-radius: 16px;
  font-weight: bold;
  width: 220px;
  height: 50px;
}

.circulo-end-encuesta {
  width: 110px;
  height: 110px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: #09D4FC;
}

.estilo-textarea-encuesta {
  border: 2px solid #09D4FC;
  border-radius: 15px;
  font-size: 22pt
}


.confirm-Button-end-encuesta {
  background-color: #09D4FC;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  border-radius: 10px;
  font-weight: bold;
  width: 150px;
  height: 40px;
}

.zoom {
  transition: transform .2s;
}

.zoom:hover {
  transform: scale(1.5);
}


.containerFotos {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  flex-direction: row;
}

.border-canvas {
  border: 2px solid #09D4FC;
  border-radius: 22px;
  background-color: #ffffff;

}

.a-line {
  line-height: 130%;

}

.a-line-carta {
  line-height: 240%;
}

.label-modal-examNim {
  color: #000000;
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;

}


.cancell-1 {
  color: #000000;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
}

.cancell-button {
  background-color: #FF00AA;
  font-weight: bold;
  color: #ffffff;
  border: 3px solid #FF00AA;
  border-radius: 15px;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  width: 150px;
  height: 40px;

}

.cancell-2 {
  color: #000000;
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
}

/* 1600 */
/* 1024 */


.box-departamento {
  border: 3px solid #0079C7;
  background: #0079C7;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  height: 50px;
}



@media screen and (max-width: 4460px) {
  .box-reloj {
    border: 3px solid #0079C7;
    background: #46AFE5;
    border-radius: 25px;
    height: 65px;
  }

  .r {
    color: #ffffff;
    font-size: 30px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    text-align: center;
  }



  .labelCode {
    font-size: 21px !important;
  }

  .imgCodeBar {
    width: 240px !important;
  }

  .confirm-Button-study {
    background-color: #09D4FC;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 18px;
    font-family: 'Montserrat', sans-serif;
    border-radius: 24px;
    font-weight: bold;
    width: 280px;
    height: 50px;
  }

  .circulo {
    width: 80px;
    height: 80px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background: #AA99FF;
    text-align: center;
    /* margin-left: 40px !important; */
  }

  .rectanguloObservations {
    width: 300px;
    height: 250px;
    background: #aed9efad;
    opacity: 10;
    border-radius: 25px;
    box-shadow: 0 0 10px #46afe5;
    transition: all 0.3s ease;
  }




  .imgTomaStudy {
    width: 54% !important;
  }

  .table-scroll-Sammpling {
    height: 400px;
    /*408*/
    overflow: auto;
    margin-top: 0px;
    /*20*/
    display: block;
  }


  .na {
    color: #000000;
    font-size: 34px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    text-align: center;
  }

  .confirm-Button-1-encuesta-vacio {
    background-color: #ffffff;
    border-color: #09D4FC;
    border-width: 3px;
    border-style: solid;
    color: #09D4FC;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 32px;
    font-family: 'Montserrat', sans-serif;
    border-radius: 16px;
    font-weight: bold;
    width: 280px;
    height: 60px;
  }


  .confirm-Button-1-encuesta-lleno {
    background-color: #09D4FC;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 32px;
    font-family: 'Montserrat', sans-serif;
    border-radius: 16px;
    font-weight: bold;
    width: 280px;
    height: 60px;
  }

  .confirm-Button-finalizar-encuesta {
    background-color: #09D4FC;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 22px;
    font-family: 'Montserrat', sans-serif;
    border-radius: 15px;
    font-weight: bold;
    width: 380px;
    height: 50px;
  }

  /* .divMenu {
    left: 800px;
    top: 150px
  } */

  .manualButton-toma {
    background-color: #0079C7;
    font-weight: bold;
    color: #ffffff;
    border: 3px solid #0079C7;
    border-radius: 18px;
    font-size: 18px;
    font-family: 'Montserrat', sans-serif;
    width: 22%;
    height: 50px;
  }





}


@media screen and (max-width: 1199.5px) {


  .divMenu {
    left: 650px;
    top: 90px
  }

  .manualButton {
    background-color: #0079C7;
    font-weight: bold;
    color: #ffffff;
    border: 3px solid #0079C7;
    border-radius: 18px;
    font-size: 18px;
    font-family: 'Montserrat', sans-serif;
    width: 300px;
    height: 50px;
  }




}

@media screen and (max-width: 1280px) {

  .divMenu {
    left: 920px;
    top: 70px
  }



}




/* dna size  */

@media screen and (max-width: 1024px) {


  .rectanguloObservations {
    width: 240px;
    height: 190px;
    background: #aed9efad;
    opacity: 10;
    border-radius: 25px;
    box-shadow: 0 0 10px #46afe5;
    transition: all 0.3s ease;
  }

  .circulo {
    width: 80px !important;
    height: 80px !important;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background: #AA99FF;
    text-align: center;
    margin-left: 15px !important;
  }

}





/* size tablet */

@media screen and (max-width: 1130px) {

  .box-reloj {
    border: 3px solid #0079C7;
    background: #46AFE5;
    border-radius: 25px;
    height: 55px;
  }

  .r {
    color: #ffffff;
    font-size: 25px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    text-align: center;
  }

  /* 
  .box-departamento {
    border: 3px solid #0079C7;
    background: #0079C7;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    height: 55px;
  } */

  .labelCode {
    font-size: 18px !important;
  }

  .imgCodeBar {
    width: 240px !important;

  }

  .confirm-Button-1-encuesta-vacio {
    background-color: #ffffff;
    border-color: #09D4FC;
    border-width: 3px;
    border-style: solid;
    color: #09D4FC;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 32px;
    font-family: 'Montserrat', sans-serif;
    border-radius: 16px;
    font-weight: bold;
    width: 280px;
    height: 60px;
  }

  .confirm-Button-1-encuesta-lleno {
    background-color: #09D4FC;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 32px;
    font-family: 'Montserrat', sans-serif;
    border-radius: 16px;
    font-weight: bold;
    width: 280px;
    height: 60px;
  }


  .divMenu {
    left: 650px;
    top: 70px
  }

  .manualButton {
    background-color: #0079C7;
    font-weight: bold;
    color: #ffffff;
    border: 3px solid #0079C7;
    border-radius: 18px;
    font-size: 18px;
    font-family: 'Montserrat', sans-serif;
    width: 300px;
    height: 50px;
  }




}

/* dna size  */

@media screen and (max-width: 1024px) {


  .rectanguloObservations {
    width: 240px;
    height: 190px;
    background: #aed9efad;
    opacity: 10;
    border-radius: 25px;
    box-shadow: 0 0 10px #46afe5;
    transition: all 0.3s ease;
  }

  .circulo {
    width: 80px !important;
    height: 80px !important;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background: #AA99FF;
    text-align: center;
    margin-left: 15px !important;
  }

}





/* size tablet */

@media screen and (max-width: 1130px) {
  .box-reloj {
    border: 3px solid #0079C7;
    background: #46AFE5;
    border-radius: 25px;
    height: 50px;
  }

  .r {
    color: #ffffff;
    font-size: 23px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    text-align: center;
  }

  /* .box-departamento {
    border: 3px solid #0079C7;
    background: #0079C7;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    height: 50px;
  } */

  .labelCode {
    font-size: 22px !important;
  }

  .imgCodeBar {
    width: 240px !important;
  }

  .confirm-Button-study {
    background-color: #09D4FC;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 17px;
    font-family: 'Montserrat', sans-serif;
    border-radius: 24px;
    font-weight: bold;
    width: 260px;
    height: 50px;
  }

  .circulo {
    width: 140px;
    height: 140px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background: #AA99FF;
    text-align: center;
    /* margin-left: 40px !important; */
  }

  .imgTomaStudy {
    width: 50% !important;
  }

  .table-scroll-Sammpling {
    height: 280px !important;
    /*408*/
    overflow: auto;
    margin-top: 0px;
    /*20*/
    display: block;
  }


  .na {
    color: #000000;
    font-size: 30px !important;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    text-align: center;
  }

  .confirm-Button-1-encuesta-vacio {
    background-color: #ffffff;
    border-color: #09D4FC;
    border-width: 3px;
    border-style: solid;
    color: #09D4FC;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 26px;
    font-family: 'Montserrat', sans-serif;
    border-radius: 16px;
    font-weight: bold;
    width: 250px;
    height: 60px;
  }


  .confirm-Button-1-encuesta-lleno {
    background-color: #09D4FC;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 26px;
    font-family: 'Montserrat', sans-serif;
    border-radius: 16px;
    font-weight: bold;
    width: 250px;
    height: 60px;
  }


  .confirm-Button-finalizar-encuesta {
    background-color: #09D4FC;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 26px;
    font-family: 'Montserrat', sans-serif;
    border-radius: 15px;
    font-weight: bold;
    width: 380px;
    height: 50px;
  }

  .divMenu {
    left: 690px;
    top: 80px
  }

  .manualButton {
    background-color: #0079C7;
    font-weight: bold;
    color: #ffffff;
    border: 3px solid #0079C7;
    border-radius: 18px;
    font-size: 18px;
    font-family: 'Montserrat', sans-serif;
    width: 300px;
    height: 50px;
  }








}




@media screen and (max-width: 575.99px) {}


.tap-top-cat {
  background: #7366ff;
  background: var(--theme-deafult);
  border: none;
  border-radius: 100%;
  bottom: 30px;
  box-shadow: 0 0 10px #7366ff;
  box-shadow: 0 0 10px var(--theme-deafult);
  color: #fff;
  cursor: pointer;
  display: none;
  height: 40px;
  opacity: .5;
  padding: 7px;
  position: fixed;
  right: 30px;
  text-align: center;
  transition: all .3s ease;
  width: 40px;
  z-index: 100;
  display: block;
}

/* clases nuevas para toma de muestra */


.labelToma1 {
  color: #ffffff;
  font-size: 22px;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  text-align: center;
}

.bottonIniciarMuestra {
  background-color: #0079C7;
  font-weight: bold;
  color: #ffffff;
  border: 3px solid #0079C7;
  border-radius: 18px;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  width: 22%;
  height: 50px;
}

.labelIniciarMuestra {
  font-size: 18px !important;
}

.username-Active-Muestra {
  width: 20%;
  height: 62px;
  background: #46afe5;
  border-radius: 25px;
  box-shadow: 0 0 10px #46afe5;
  transition: all 0.3s ease;
}

.label-menuToma {
  color: #ffffff;
  font-size: 13px;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  text-align: center;
}

.confirm-Button-study-Toma1 {
  background-color: #09D4FC;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  font-family: 'Montserrat', sans-serif;
  border-radius: 24px;
  font-weight: bold;
  width: 260px;
  height: 50px;
}

/* //url(static/media/menu-background.f999cff5.jpg) */



/* .deni-react-treeview-container {
  background: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8));
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border-color: #004D40;
  font-family: tahoma;
  font-size: 14px;
  width: 350px;
  height: 500px;
  opacity: 1;
  box-shadow: 3px 3px 4px #b2dfdb;
  border-radius: 5px;
} */

/* background: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), url(static/media/menu-background.f999cff5.jpg); */

/* ../../../assetsToma/imagenes/Frascos.gif */

.theme-customization2 {
  background: linear-gradient(rgba(211, 224, 255, 0.8), rgba(255, 255, 255, 0.8));
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border-color: #004D40;
  font-family: tahoma;
  font-size: 14px;

  opacity: 1;
  box-shadow: 3px 3px 4px #B2DFDB;
  border-radius: 5px
}

.theme-customization2 .deni-react-treeview-container::-webkit-scrollbar {
  width: 8px
}

.theme-customization2 .deni-react-treeview-container::-webkit-scrollbar-track {
  background: rgba(144, 164, 174, 0.1)
}

.theme-customization2 .deni-react-treeview-container::-webkit-scrollbar-thumb {
  background: rgba(144, 164, 174, 0.3)
}

.theme-customization2 .deni-react-treeview-container::-webkit-scrollbar-thumb:hover {
  background: rgba(144, 164, 174, 0.6)
}

.theme-customization2 .deni-react-treeview-container .deni-react-treeview-item-container {
  height: 28px
}

.theme-customization2 .deni-react-treeview-container .deni-react-treeview-item-container .icon-and-text {
  color: #00695C;
  padding-top: 13px;
  padding-bottom: 13px;
  display: flex;
  flex-direction: row;
  align-items: center
}

.theme-customization2 .deni-react-treeview-container .deni-react-treeview-item-container .icon-and-text.selected {
  color: #0fceae !important;
  border-color: #80CBC4;
  background: linear-gradient(to bottom, #E0F2F1 0%, #80CBC4 100%) !important
}

.theme-customization2 .deni-react-treeview-container .deni-react-treeview-item-container .icon-and-text .icon {
  width: 24px !important;
  height: 24px !important;
  /* background-image: url(static/media/folder-green.bde66c33.png) !important; */
  background-size: 24px 24px
}

/* .theme-customization2 .deni-react-treeview-container .deni-react-treeview-item-container .icon-and-text .icon.expanded {
  background-image: url(static/media/folder-green.bde66c33.png) !important
}

.theme-customization2 .deni-react-treeview-container .deni-react-treeview-item-container .icon-and-text .icon.isleaf {
  background-image: url(static/media/dog.bee52b1c.png) !important
} */



.deni-react-treeview-container.green .deni-react-treeview-item-container.green .icon-and-text.selected {
  background: #ebf9f7;
  background: linear-gradient(to bottom, #ebf9f7 0%, #ffffff 100%);
}



.grefusedTrackin {
  background-color: #f9dadd;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border-radius: 100%; */
}

.userTracking {
  background-color: rgba(0, 121, 199, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  width: 95px;
  height: 95px;
}

.colorRefused {
  background-color: rgba(0, 121, 199, 0.1);
}

.prueba1w {
  margin-right: 20px !important;
}

.sizeEmojiEncuesta {
  font-size:  110px !important
}

.sizeCol {
  background-color: #46AFE5!important;
  color: #fff !important;
    font-size:  14px !important;
    
}

.form-exams{
  width: 400px;
  height: 290px;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.custom-box {
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 5px;
}

.completeExams{
  color: #fff;
  background: linear-gradient(90deg, hsla(192, 95%, 50%, 1) 0%, hsla(225, 89%, 47%, 1) 100%);
  border: none;
  padding: 7px;
  box-shadow: 0 0 10px var(--theme-deafult);
  opacity: 5;
  transition: all 0.3s ease;
}



.span {
  color: #237695;
}
