.quoterOrder {
    background-color:  #7366ff ;
  }

.quoterOrderResumen {
    background-color:  #DC3545 ;
  }

  .quoterOrder-Resumen {
    background-color:  #007BFF ;
  }

  .icon-Exam {
    color:  #0079C7 ;
  }

  .icon-Profile {
    color:  #0079C7 ;
  }


.button-loading {
    width: 320px;
    height: 35px;
}

.quoterOrder-Resumen-fondo {
    background-color:  #ececec ;
  }

.quoter-order-resumen{
    background-color:  #DC3545 ;

}

.button-doctor {
    width: 15px;
    height: 34px;
}

.bottonquoter {
  width: 130px;
  height: 34px;
}

.CardTitle{
  background: linear-gradient(270deg, #0079C7 0%, #FFFFFF 85.94%)
}

.lineD {
  /* border-color: #46AFE5;
  border-width: 1px; */

  border-color: #0079C7;
  background-color: #46AFE5;
  border-width: 2px;
  border-style: solid;

  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  
}

.scrollWork {
  width: 500px;
  height: 220px;
  overflow: auto;
}

@media screen and (max-width: 1460px) {
  .cardIndication{
    font-weight: 500 !important;
    font-size: 16px !important;
  }
  .imgCritic{
    width: 120% !important;
  }
  .sizeCard{
    height: 140px  !important;
  }
  .pExams{
    color: #000000 !important;
    font-size: 14px !important;
  }
  .pExamsChart{
    color: #000000 !important;
    font-size: 14px !important;
  }
  .labelpri{
    font-size: 13px !important; 
  }
  .labelNim{
    font-size: 13px !important; 
  }
  .labelcreatePatient{
    font-size: 12px !important; 
  }
  .labelResumen{
    font-size: 14px !important; 
    font-weight: 500 !important;
  }
  .labelChange{
    font-size: 15px !important; 
  }
  .labelIndication{
    font-size: 13px !important; 
    font-weight: 600 !important;
  }
  .iconPatien{
    font-size: 8px !important; 
  }
  .sizeModalDP{
    height: 400px  !important;
  }

  .sizeModalDPAn{
    height: 320px  !important;
  }
  
  .labelNewPatient{
    font-size: 10px !important;
  }
  .labSampleMicrobiology{
    font-size: 12px !important;
  }
  .labelSampleMicrobiologyP{
    font-size: 12px !important;
  }
  .labelSampleMicrobiologyC{
    font-size: 12px !important;
  }
  .sizeModalMicrobiology{
    height: 230px  !important;
  }
  .labelDateGenerales{
    font-size: 12px !important;
  }
  .labelSiteId{
    font-size: 13px !important;
  }
  .labelSiteArea{
    font-size: 13px !important;
  }
  .labelTomaSite{
    font-size: 13px !important;
  }
  .labelComentariosTomaSite{
    font-size: 13px !important;
  }
  .labelOrderPriority{
    font-size: 13px !important;
  }
  .labelPrintSite{
    font-size: 13px !important;
  }
  .labelMuestreo{
    font-size: 13px !important;
  }
  .labelTextPrint{
    font-size: 13px !important;
  }
  .labelResultMuestreo{
    font-size: 13px !important;
  }
  .labelTemperaturaSpecimen{
    font-size: 13px !important;
  }
  .labelPesoSpecimen{
    font-size: 13px !important;
  }
  .labelVolumenSpecimen{
    font-size: 13px !important;
  }
  .labelNewSite{
    font-size: 10px !important;
  }
  .labelPatientSize{
    font-size: 12px !important;
  }

  .ObservationFlebo {
    height: 100px !important;
    width: 600px !important;
}

}

@media screen and (max-width: 1199.5px) {
  .cardIndication{
    font-size: 16px !important;
    font-weight: 500 !important;
  }
  .imgCritic{
    width: 120% !important;
  }

  .sizeCard{
    height: 140px  !important;
  }
  .pExams{
    color: #000000 !important;
    font-size: 14px !important;
  }
  .pExamsChart{
    color: #000000 !important;
    font-size: 10px !important;
  }
  .labelpri{
    font-size: 8px !important; 
  }
  .labelNim{
    font-size: 13px !important; 
  }
  .labelcreatePatient{
    font-size: 12px !important; 
  }
  .labelResumen{
    font-size: 14px !important; 
    font-weight: 500 !important;
  }
  .labelChange{
    font-size: 15px !important; 
  }
  .labelIndication{
    font-size: 13px !important; 
    font-weight: 600 !important;
  }
  .iconPatien{
    font-size: 8px !important; 
  }
  .sizeModalDP{
    height: 330px  !important;
  }
  .labelNewPatient{
    font-size: 8px !important;
  }
  .labSampleMicrobiology{
    font-size: 10px !important;
  }
  .labelSampleMicrobiologyP{
    font-size: 10px !important;
  }
  .labelSampleMicrobiologyC{
    font-size: 10px !important;
  }
  .sizeModalMicrobiology{
    height: 230px  !important;
  }
  .labelDateGenerales{
    font-size: 11px !important;
  }
  .labelSiteId{
    font-size: 11px !important;
  }
  .labelSiteArea{
    font-size: 11px !important;
  }
  .labelTomaSite{
    font-size: 11px !important;
  }
  .labelComentariosTomaSite{
    font-size: 11px !important;
  }
  .labelOrderPriority{
    font-size: 11px !important;
  }
  .labelPrintSite{
    font-size: 11px !important;
  }
  .labelMuestreo{
    font-size: 11px !important;
  }
  .labelTextPrint{
    font-size: 11px !important;
  }
  .labelResultMuestreo{
    font-size: 11px !important;
  }
  .labelTemperaturaSpecimen{
    font-size: 11px !important;
  }
  .labelPesoSpecimen{
    font-size: 11px !important;
  }
  .labelVolumenSpecimen{
    font-size: 11px !important;
  }
  .labelNewSite{
    font-size: 10px !important;
  }
  .labelPatientSize{
    font-size: 12px !important;
  }

}

@media screen and (max-width: 1130px){
  .cardIndication{
    font-weight: 500 !important;
    font-size: 10px !important;
  }

  .imgCritic{
    width: 140% !important;
  }

  .sizeCard{
    height: 110px  !important;
  }
  .pExams{
    color: #000000 !important;
    font-size: 10px !important;
  }
  .pExamsChart{
    color: #000000 !important;
    font-size: 10px !important;
  }
  .labelpri{
    font-size: 11px !important; 
  }
  .labelNim{
    font-size: 11px !important; 
  }
  .fIcon {
    font-size: 16px !important;
  }
  .labelFilter{
    font-size: 12px !important; 
  }
  .fIconFilter {
    font-size: 16px !important;
  }
  .labelMensaje{
    font-size: 14px !important; 
  }
  .labelSearchPatient{
    font-size: 12px !important; 
  }
  .labelcreatePatient{
    font-size: 11px !important; 
  }
  .labelIndication{
    font-size: 11px !important; 
  }
  .labelResumen{
    font-size: 11px !important; 
    font-weight: 500 !important;

  }
  .labelButton{
    font-size: 10px !important; 
  }
  .labelPrices{
    font-size: 10px !important; 
  }
  .labelSaveCreate{
    font-size: 10px !important; 
    font-weight: 500 !important;

  }
  .labelChange{
    font-size: 13px !important; 
  }
  .labelIndication{
    font-size: 11px !important; 
    font-weight: 500 !important;
  }
  .PIndication{
    font-size: 10px !important; 
  }
  .labelCheckPrint{
    font-size: 10px !important; 
  }
  .labelPriority{
    font-size: 11px !important; 
  }
  .iconPatien{
    font-size: 8px !important; 
  }
  .sizeModalDP{
    height: 360px  !important;
  }
  .labelNewPatient{
    font-size: 10px !important;
  }
  .labSampleMicrobiology{
    font-size: 11px !important;
  }
  .labelSampleMicrobiologyP{
    font-size: 11px !important;
  }
  .labelSampleMicrobiologyC{
    font-size: 11px !important;
  }
  .sizeModalMicrobiology{
    height: 230px  !important;
  }
  .labelDateGenerales{
    font-size: 11px !important;
  }
  .labelSiteId{
    font-size: 11px !important;
  }
  .labelSiteArea{
    font-size: 11px !important;
  }
  .labelTomaSite{
    font-size: 11px !important;
  }
  .labelComentariosTomaSite{
    font-size: 11px !important;
  }
  .labelOrderPriority{
    font-size: 11px !important;
  }
  .labelPrintSite{
    font-size: 11px !important;
  }
  .labelMuestreo{
    font-size: 11px !important;
  }
  .labelTextPrint{
    font-size: 11px !important;
  }
  .labelResultMuestreo{
    font-size: 11px !important;
  }
  .labelTemperaturaSpecimen{
    font-size: 11px !important;
  }
  .labelPesoSpecimen{
    font-size: 11px !important;
  }
  .labelVolumenSpecimen{
    font-size: 11px !important;
  }
  .labelNewSite{
    font-size: 10px !important;
  }

}

@media screen and (max-width: 575.99px){
  .cardIndication{
    font-weight: 500 !important;
    font-size: 13px !important;
  }

  .imgCritic{
    width: 100% !important;
  }

  .sizeCard{
    height: 110px  !important;
  }
  .pExams{
    color: #000000 !important;
    font-size: 10px !important;
  }
  .pExamsChart{
    color: #000000 !important;
    font-size: 10px !important;
  }
  .labelpri{
    font-size: 14px !important; 
  }
  .labelNim{
    font-size: 10px !important; 
  }
  .fIcon {
    font-size: 16px !important;
  }
  .labelFilter{
    font-size: 12px !important; 
  }
  .fIconFilter {
    font-size: 16px !important;
  }
  .labelMensaje{
    font-size: 14px !important; 
  }
  .labelSearchPatient{
    font-size: 12px !important; 
  }
  .labelcreatePatient{
    font-size: 11px !important; 
  }
  .labelIndication{
    font-size: 11px !important; 
    font-weight: 500 !important;

  }
  .labelResumen{
    font-size: 11px !important; 
    font-weight: 500 !important;

  }
  .labelButton{
    font-size: 10px !important; 
  }
  .labelPrices{
    font-size: 10px !important; 
  }
  .labelSaveCreate{
    font-size: 10px !important; 
    font-weight: 500 !important;

  }
  .labelChange{
    font-size: 13px !important; 
  }
  .labelIndication{
    font-size: 11px !important; 
    font-weight: 500 !important;
  }
  .PIndication{
    font-size: 10px !important; 
  }
  .labelCheckPrint{
    font-size: 10px !important; 
  }
  .labelPriority{
    font-size: 11px !important; 
  }
  .iconPatien{
    font-size: 8px !important; 
  }
  .sizeModalDP{
    height: 310px  !important;
  }
  .labelNewPatient{
    font-size: 8px !important;
  }
  .labSampleMicrobiology{
    font-size: 10px !important;
  }
  .labelSampleMicrobiologyP{
    font-size: 10px !important;
  }
  .labelSampleMicrobiologyC{
    font-size: 10px !important;
  }
  .sizeModalMicrobiology{
    height: 230px  !important;
  }
  .labelDateGenerales{
    font-size: 11px !important;
  }
  .labelSiteId{
    font-size: 11px !important;
  }
  .labelSiteArea{
    font-size: 11px !important;
  }
  .labelTomaSite{
    font-size: 11px !important;
  }
  .labelComentariosTomaSite{
    font-size: 11px !important;
  }
  .labelOrderPriority{
    font-size: 11px !important;
  }
  .labelPrintSite{
    font-size: 11px !important;
  }
  .labelMuestreo{
    font-size: 11px !important;
  }
  .labelTextPrint{
    font-size: 11px !important;
  }
  .labelResultMuestreo{
    font-size: 11px !important;
  }
  .labelTemperaturaSpecimen{
    font-size: 11px !important;
  }
  .labelPesoSpecimen{
    font-size: 11px !important;
  }
  .labelVolumenSpecimen{
    font-size: 11px !important;
  }
  .labelNewSite{
    font-size: 10px !important;
  }

  
}

.prubalabel{
  color:  rgba(19, 19, 204, 0.34);
  background-color: rgb(230, 133, 16)
}


.text-WorkOrder {
  color: #2b2b2b;
  font-size: 12px !important;
}


.larger-toast {
  font-size: 18px; 
}

.tree-node {
  margin-bottom: 10px;
}

.node-header {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.icon-Exam {
  margin-right: 8px;
  transition: transform 0.3s ease;
}

.icon-Exam.open {
  transform: rotate(90deg); /* O el ángulo que prefieras para el ícono */
}

.tree-list {
  margin-left: 20px;
  list-style-type: none; /* Elimina los puntos predeterminados de las listas */
}

.tree-list li {
  margin-bottom: 5px;
}

.labelworkOrder {
  display: block;
}

.Bonusses {

  background-color: #fa7272 !important;
  color: #FFFFFF;

}


.deni-react-treeview-container-micro {
  font-size: 12px;
  padding: 0px;
  overflow-x: auto;
  overflow-y: auto;
  border: solid 1px;
  border-color: #51bb25;
  width: 430px;
  height: 250px;
  background-color: #51bb2514;
}





