.background-container {
    background-image: url('../../../assets/images/price/reportCxc.svg'); 
    background-size: cover;
    background-position: center;
    padding: 20px; 
    height: 160px;
    width: 150px;
    color: #fff; 
  }
  .background-container-Pending {
    background-image: url('../../../assets/images/price/reportCxcPending.svg'); 
    background-size: cover;
    background-position: center;
    padding: 20px; 
    height: 160px;
    width: 150px;
    color: #fff; 
  }

  .background-container-Invoices {
    background-image: url('../../../assets/images/price/reportCxcInvoice.svg'); 
    background-size: cover;
    background-position: center;
    padding: 20px; 
    height: 160px;
    width: 150px;
    color: #fff; 
  }

  .tableSF {
    background-color:  #ff8c971c !important;

  }

  .scroll-container-cancell {
    max-height: 420px;
    overflow-y: auto; 
    position: relative;
}

.table-footer {
    position: sticky;
    bottom: 0;
    background-color: #fff;
    z-index: 1; 
}

.card-Reportcxc {
  background: var(--surface-card);
  border: 1px solid var(--surface-border);
  padding: 1.5rem;
  margin-bottom: 2rem;
  box-shadow: var(--card-shadow);
  border-radius: 12px;
}

.bg-blue-100-cxc {
  background-color: #96abcc !important;
  width: 40px;        
  height: 40px;       
  border-radius: 8px; 
}

.bg-blue-100-cxc i {
  font-size: 20px;    
  color: white;      
}

.d-flex {
  display: flex;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-between {
  justify-content: space-between !important;
}

.align-items-center {
  align-items: center;
}
.bg-blue-100-cxc1 {
  background-color: #ff9c40 !important;
  width: 40px;        
  height: 40px;       
  border-radius: 8px; 
}

.bg-blue-100-cxc1 i {
  font-size: 20px;    
  color: white;      
}
.bg-blue-100-cxc2 {
  background-color: #0079c794 !important;
  width: 40px;        
  height: 40px;       
  border-radius: 8px; 
}

.bg-blue-100-cxc2 i {
  font-size: 20px;    
  color: white;      
}

.bg-blue-100-cxc3 {
  background-color: #f8d62bb5 !important;
  width: 40px;        
  height: 40px;       
  border-radius: 8px; 
}

.bg-blue-100-cxc3 i {
  font-size: 20px;    
  color: white;      
}

.custom-card {
  padding: 1.5rem; /* Espaciado dentro de la tarjeta */
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px; /* Separar tarjetas */
}

.bar-container {
  position: relative;
  width: 100%;
  margin-bottom: 20px; /* Espacio entre las barras */
}

.bar {
  height: 12px; /* Altura de la barra */
  border-radius: 6px; /* Bordes más redondeados */
}

.bar-label {
  position: absolute;
  top: 15px; /* Ubicación del texto sobre la barra */
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #000000;
}

.bar-label span {
  font-weight: 600;
}

.mb-3 {
  margin-bottom: 15px; /* Más espacio entre los elementos */
}

.mt-3 {
  margin-top: 15px; /* Más espacio en la parte superior de la tarjeta */
}








  


  